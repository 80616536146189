* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Poppins", "Montserrat", sans-serif;
	scroll-behavior: smooth;
}

body {
}

.primary {
	color: #00a8ff;
}

:root {
	/* --primary: #4c5e3f; */
	/* --primary: #5cc9ff; */
	--primary: #009279;
	/* --primary-dark: #1c2714; */
	/* --primary-dark: #01334c; */
	--primary-dark: #00332a;
}
